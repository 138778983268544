import { useEffect, useState } from "react";
import { Col, Container, Row, Spinner, Tab } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import SelectorIdioma from "../../components/SelectorIdioma/SelectorIdioma";
import Calendario from "../../components/calendario/calendario";
import ciclosService from "../../services/ciclos";
import eventosService from "../../services/eventos";

function ListaEventos({ ciclo_key }) {
 
  const [t] = useTranslation("global");
  const [loading, setloading] = useState(true);
  const [eventos, seteventos] = useState();

  const [ciclo, setciclo] = useState();
  const history = useHistory();
  const [ciclos, setCiclos] = useState();

  useEffect(() => {
    async function getEventsCiclos() {
      const ciclos_temp = await ciclosService.getCiclos();
      setCiclos(ciclos_temp);
      for (const ciclo_temp of ciclos_temp) {
        if (ciclo_temp.tags[1] === ciclo_key) {
          setciclo(ciclo_temp);
          await eventosService.getEventsCiclo(ciclo_temp.name).then((res) => {
            seteventos(res);
            if (loading) {
              setloading(false);
            }
          });
        }
      }
    }


    getEventsCiclos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ciclo_key]);



  return (
    <Container fluid className="home">
      <Row style={{ height: "40px", width: '100%' }}>
        <Col
          style={{
            marginTop: "5px",
            justifyContent: "flex-start",
            display: "flex",
            alignItems: "center",
          }}
        >
          <SelectorIdioma />
        </Col>
      </Row>

      <Row>
        <div
          className="bannerHome"
        ></div>
      </Row>

      <Row style={{ display: "flex", justifyContent: "center" }}>
        <div className="selectorHome">
          <div className="flex-row-Home">
            <div className="flex-colHome">
              <div className="selecciona-el-cicloHome">
                {t("header.Seleciona-el-ciclo")}
              </div>
            </div>
          </div>
        </div>
      </Row>

      <Row
        className="calendarioHome"
        style={{ justifyContent: "center", display: "flex" }}
      >
        <Tab.Container defaultActiveKey="musikauzo">
          <Tab.Content>
            <Tab.Pane eventKey="musikauzo">
              {loading ? (
                <Spinner animation="border" />
              ) : (
                <Calendario eventos_ciclo={eventos} ciclo={ciclo} />
              )}{" "}
            </Tab.Pane>
            <Tab.Pane eventKey="eszena-kalera">
              {loading ? (
                <Spinner animation="border" />
              ) : (
                <Calendario eventos_ciclo={eventos} ciclo={ciclo} />
              )}{" "}
            </Tab.Pane>
            {/* <Tab.Pane eventKey="clown-zorrotza">
              {loading ? (
                <Spinner animation="border" />
              ) : (
                <Calendario eventos_ciclo={eventos} ciclo={ciclo} />
              )}{" "}
            </Tab.Pane> */}
            {/* <Tab.Pane eventKey="magiaren-topaketa">
              {loading ? (
                <Spinner animation="border" />
              ) : (
                <Calendario eventos_ciclo={eventos} ciclo={ciclo} />
              )}{" "}
            </Tab.Pane> */}
            <Tab.Pane eventKey="Bertso Saioak">
              {loading ? (
                <Spinner animation="border" />
              ) : (
                <Calendario eventos_ciclo={eventos} ciclo={ciclo} />
              )}{" "}
            </Tab.Pane>
            <Tab.Pane eventKey="zirkualde">
              {loading ? (
                <Spinner animation="border" />
              ) : (
                <Calendario eventos_ciclo={eventos} ciclo={ciclo} />
              )}{" "}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Row>
    </Container>
  );
}

export default ListaEventos;