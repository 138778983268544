import "./event.css";

import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { getFechaFmt, getHoraFmt } from "./../../utils/fechas";

import ControlAcceso from "../../components/controlAcceso/controlacceso";
import InfoEvento from "../../components/InfoEvento/infoEvento";
import Reserva from "../../components/Reserva/reserva";
import SelectorIdioma from "../../components/SelectorIdioma/SelectorIdioma";
import eventosService from "../../services/eventos";
import { useTranslation } from "react-i18next";

function Evento({ eventoID }) {
  const [evento, setEvento] = useState(null);
  const [loading, setLoading] = useState(true);
  const [t, i18n] = useTranslation("global");
  const [agotado, setAgotado] = useState(true)

  let location = useLocation();

  useEffect(() => {
    async function getInfoEvento(eventoID) {
      const evento = await eventosService.getEvento(eventoID);
      //console.log(evento)
      evento.dia_fmt = getFechaFmt(evento.evento.startDate, i18n.language);
      let minutes;
      let hora = new Date(evento.evento.startDate).getHours();
      if (new Date(evento.evento.startDate).getMinutes() < 10) {
        minutes = "0" + new Date(evento.evento.startDate).getMinutes();
      } else {
        minutes = new Date(evento.evento.startDate).getMinutes();
      }
      evento.evento.hora_fmt = hora + ":" + minutes;

      evento.hora_fmt = getHoraFmt(evento.evento.startDate, i18n.language);
      evento.inicio = getFechaFmt(evento.evento.startDate, i18n.language);
      evento.fin = getFechaFmt(evento.evento.endDate, i18n.language);
      evento.tickets.forEach((element) => {
        element.hora_fmt = getHoraFmt(element.startDate, i18n.language);
      });
      setEvento(evento);

      for(const ticket of evento.tickets){
        if(ticket.amount > ticket.sellTickets){
          setAgotado(false)
        }
      }
  

      if (loading) {
        setLoading(false);
      }
    }

    getInfoEvento(eventoID);
  }, [eventoID, loading, i18n.language]);

  const eventoData = {
    iconMetroLocation:
      "https://anima-uploads.s3.amazonaws.com/projects/608138be200c27f99805dc8c/releases/60828d4c841fac091e8381dd/img/icon-metro-location@1x.png",
    overlapGroup13:
      "https://anima-uploads.s3.amazonaws.com/projects/608138be200c27f99805dc8c/releases/60828d4c841fac091e8381dd/img/rectangle-166@1x.png",
  };

  return (
    <div>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Container className="evento" style={{ padding: "0" }}>
          {/**BANNER EVENTO */}

          <Row style={{ backgroundColor: "white", height: "40px" }}>
            <Col
              style={{
                justifyContent: "flex-start",
                display: "flex",
                alignItems: "start",
              }}
            >
              <SelectorIdioma />
            </Col>
            {/* <Col
                style={{
                  justifyContent: "flex-end",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {location.pathname.includes("entradas") ||
                location.pathname.includes("controlacceso") ? (
                  <div></div>
                ) : (
                  <Button variant="flat">
                    <Link
                      to={evento.evento._id + "/controlacceso"}
                      color="black"
                    >
                     {t("header.acceso-regidores")}
                    </Link>
                  </Button>
                )}
              </Col> */}
          </Row>
          <Row
            style={{
              backgroundImage: `url(${evento.evento.banner})`,
              height: "500px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPositionX: "center",
              backgroundPositionY: "30%",
              width: "inherit",
              margin: "0",
            }}
          ></Row>
          <Row
            style={{
              justifyContent: "center",
              width: "100%",
              height: "maxContent",
              margin: "0",
            }}
          >
            <div className="resumenEven">
              <h2 className="text-4Even">{evento.evento.name}</h2>
              <div className="group-550Even">
                <img
                  className="icon-metro-locationEven"
                  src={eventoData.iconMetroLocation}
                  alt=""
                />
                <div className="text-6Even montserrat-regular-normal-thunder-30px">
                  {evento.evento.ubication.location}
                </div>
              </div>
              {evento.tickets.length === 0 ? (
                <div className="text-5Even montserrat-regular-normal-thunder-30px">
                  {evento.dia_fmt} {evento.evento.hora_fmt}
                </div>
              ) : (
                <div>
                  {evento.tickets.length > 1 ? (
                    <div>
                      {evento.evento.tags[0] === "Iralabarri bisitak" || evento.evento.tags[0] === "Zabala bisitak" ? (
                        <div className="text-5Even montserrat-regular-normal-thunder-30px">
                          {evento.inicio} {" - "}
                          {evento.fin}
                        </div>
                      ) : (
                        <div className="text-5Even montserrat-regular-normal-thunder-30px">
                          {evento.dia_fmt} {evento.tickets[0].hora_fmt}
                          {" / " + evento.tickets[1].hora_fmt}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="text-5Even montserrat-regular-normal-thunder-30px">
                      {evento.dia_fmt} {evento.tickets[0].hora_fmt}
                    </div>
                  )}
                </div>
              )}

              {location.pathname.includes("entradas") ||
              location.pathname.includes("controlacceso") ||
              evento.tickets.length === 0 ? (
                <div style={{ marginTop: "20px" }}>
                  {evento.tickets.length === 0 ? "" : ""}
                </div>
              ) : (
                <div className="buttonnormal">
                  {evento.tickets[0].grossPrice > 0 ? (
                    <Link
                      to={{
                        pathname:
                          "https://eventos.ontourup.com/event/" +
                          evento.evento.qr +
                          "/tickets",
                      }}
                      target="_blank"
                    >
                      <div
                        className="overlap-group1-2"
                        style={{
                          backgroundImage: `url(${eventoData.overlapGroup13})`,
                        }}
                      >
                        <div className="reservar-entradas">
                          {t("header.compra-entradas")}
                        </div>
                      </div>
                    </Link>
                  ) : (
                    <>
                    
                      {agotado ? (
                        <Button
                          class="btn btn-primary"
                          style={{ backgroundColor: "white", borderColor:"gray", color: "black" }}
                          disabled
                        >
                          {t("header.mensaje-agotado")}
                        </Button>
                      ) : (
                        <Link to={evento.evento._id + "/entradas"}>
                          <div
                            className="overlap-group1-2"
                            style={{
                              backgroundImage: `url(${eventoData.overlapGroup13})`,
                            }}
                          >
                            <div className="reservar-entradas" style={{lineHeight:"35px"}}>
                              {t("header.reserva-entradas")}
                            </div>
                          </div>
                        </Link>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </Row>
          {/**FIN BANNER EVENTO */}
          <Switch>
            <Route exact path="/evento/:eventoId">
              <Container style={{ marginTop: "50px" }}>
                <InfoEvento evento={evento.evento} eventoData={eventoData} />
              </Container>
            </Route>
            <Route path="/evento/:eventoId/entradas">
              <Reserva ticket={evento.tickets} evento={evento.evento}></Reserva>
            </Route>
            <Route path="/evento/:eventoId/controlacceso">
              <ControlAcceso evento={evento.evento} ticket={evento.tickets} />
            </Route>
          </Switch>
        </Container>
      )}{" "}
    </div>
  );
}

export default Evento;
