import "../../styles/styles.css";
import "./../../utils/fechas";

import { Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//import { getDiaEvento, getFechaFmt, getHoraFmt } from "./../../utils/fechas";

function CartaEvento({ evento }) {
  //console.log(evento.tags[0]);
  const [t] = useTranslation("global");
  useEffect(() => {}, [evento]);
  return (
    <React.Fragment>
      <div className="carta-evento">
        <Link to={{ pathname: "/evento/" + evento._id }}>
          <div className="overlap-groupCart">
            <Row>
              <div
                className="cartaEve"
                style={{ backgroundImage: `url(${evento.banner})` }}
              ></div>
            </Row>
            {evento.tags[0] === "Errekaldeberri bisitak" ||
            evento.tags[0] === "Errekaldeberri familian" ? (
              <div></div>
            ) : (
              <div className="fechaEve">
                <h1 className="numberEve">{evento.dia_fmt}</h1>
              </div>
            )}

            <Row>
              <div className="cartaEve2">
                {evento.tickets.length === 0 ||
                evento.tickets[0].netPrice === 0 ? (
                  <div className="infoEve" style={{ backgroundColor: "white" }}>
                    <div className="nombreEvento montserrat-semi-bold-thunder-25px">
                      {evento.name}
                    </div>
                    <Col>
                      {evento.tags[0] === "Errekaldeberri bisitak" ||
                      evento.tags[0] === "Errekaldeberri familian" ? (
                        <div></div>
                      ) : (
                        <div>
                          {evento.tickets.length === 0 ? (
                            <div className="hora-1830Eve montserrat-normal-thunder-22px">
                              {t("header.hora")}
                              {evento.hora_fmt}
                            </div>
                          ) : (
                            <div className="hora-1830Eve montserrat-normal-thunder-22px">
                              {t("header.hora")}
                              {evento.tickets[0].hora_fmt}
                              {evento.tickets.length > 1
                                ? " / " + evento.tickets[1].hora_fmt
                                : ""}
                            </div>
                          )}
                        </div>
                      )}

                      <div className="text-2Eve montserrat-normal-thunder-22px">
                        {t("header.lugar")}
                        {evento.ubication.location}
                      </div>
                    </Col>
                  </div>
                ) : (
                  <div
                    className="infoEve"
                    style={{ backgroundColor: "lightgoldenrodyellow" }}
                  >
                    <div className="nombreEvento montserrat-semi-bold-thunder-25px">
                      {evento.name}
                    </div>
                    <Col>
                      {evento.tags[0] === "Errekaldeberri bisitak" ||
                      evento.tags[0] === "Errekaldeberri familian" ? (
                        <div className="hora-1830Eve montserrat-normal-thunder-22px">
                          {t("header.todooctubre")}
                        </div>
                      ) : (
                        <div>
                          {evento.tickets.length === 0 ? (
                            <div className="hora-1830Eve montserrat-normal-thunder-22px">
                              {t("header.hora")}
                              {evento.hora_fmt}
                            </div>
                          ) : (
                            <div className="hora-1830Eve montserrat-normal-thunder-22px">
                              {t("header.hora")}
                              {evento.tickets[0].hora_fmt}
                              {evento.tickets.length > 1
                                ? " / " + evento.tickets[1].hora_fmt
                                : ""}
                            </div>
                          )}
                        </div>
                      )}

                      <div className="text-2Eve montserrat-normal-thunder-22px">
                        {t("header.lugar")}
                        {evento.ubication.location}
                      </div>                     
                    </Col>
                  </div>
                )}
              </div>
            </Row>
          </div>
        </Link>
      </div>
    </React.Fragment>
  );
}

export default CartaEvento;
