import "../styles/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

import axios from "axios";

class ControlAccesoService {
  async iniciarSesion(username, password) {
    console.log(username)
    if ((password = "ontourup")) {
      if (username === "accesoaytobilbao1@ontourup.com") {
        return {result: "ok", _id: "60924935fbf2122d245c2087"};
      }
      if (username === "accesoaytobilbao2@ontourup.com") {
        return {result: "ok", _id: "6092494dfbf2122d245c2088"};
      }
      if (username === "accesoaytobilbao2@ontourup.com") {
        return {result: "ok", _id: "60924935fbf2122d245c2089"};
      }

      if (
        username !== "accesoaytobilbao3@ontourup.com" &&
        username !== "accesoaytobilbao2@ontourup.com" &&
        username !== "accesoaytobilbao1@ontourup.com"
      ) {
        return "correo electronico incorrecto";
      }
      return "ok";
    } else {
      return "contraseña incorrecta";
    }

    /* await axios
      .post("https://eventos.ontourup.com/api/auth/login/employer", {
          username,
          password,
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        if (response.length > 0) {
          console.log("conectado");
        } else {
          console.log("El usuario o la contraseña no son correctos");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }; */
  }

  async obtenerAforo(id_empleado, id_zona, id_punto){
    axios.get("https://eventos.notifications.ontourup.com/api/v1/count/take?point=60928f09fbf2122d245c219a&zone=60928f09fbf2122d245c219a&employeer=6092494dfbf2122d245c2088")

    return 
  }

  async anadirAforo(id_empleado, id_zona, id_punto){
    
  }
}

export default new ControlAccesoService();
