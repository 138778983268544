import CustomError from "../utils/error";
import api from "./index";

class ReservaService {
  async existeUsuario(correoElectronico) {
    const res = await api.get("v1/account?email=" + correoElectronico);
    return res.data.data.email === correoElectronico;
  }

  async registrarUsuario(data) {
    const res = await api.post(
      process.env.REACT_APP_BACK_NEXT +"/auth/register/client?sendEmailRegister=false",
      data
    );
    return res.data;
  }

  async updateUsuario(data, accountID) {
    let config = {
      headers: {
        accept: "*/*",
      },
    };

    const res = await api.patch(
      "account?accountID=" +
        accountID,
      data,
      config
    );
    return res.data;
  }

  async reservar(data) {
    const res = await api.post(process.env.REACT_APP_BACK_NEXT +"/dashboard/promoter/sendEntrancesManual", data);
    return res;
  }

  async comprobarEntradas(correoElectronico, dataEntrada, amount) {

    let id_account=""

    if(correoElectronico === "bilbaokultura@ontourup.com"){
       id_account="607ee34c0d6750e22026b5c1"
    }else{
      const res = await api.get(
        process.env.REACT_APP_BACK_NEXT + "/v1/account?email=" + correoElectronico
      );
      //console.log(res.data.data._id);
       id_account = res.data.data._id;
    }
    
    const tickets = [
      {
        _id: dataEntrada.ticketsList[0].ticket,
        name: "Entrada General",
        amount: amount,
        grossPrice: 0,
        netPrice: 0,
        currency: "EUR",
      },
    ];
    const res_2 = await api.post(
      process.env.REACT_APP_BACK_NEXT +"/client/purchase/hasEntrances",
      { account: id_account, tickets: JSON.stringify(tickets) }
    );
    //console.log(res_2.data.data.results[0]);
    return res_2.data.data.results[0];
  }
  async reservarEntrada(correoElectronico, dataEntrada, dataRegistro, amount) {
    //console.log(dataRegistro);
    // eslint-disable-next-line no-unused-vars
    const res_registro = await this.registrarUsuario({
      names: dataRegistro.names,
      surnames: dataRegistro.surnames,
      email: correoElectronico,
      phone: dataRegistro.phone,
      address: { postalCode: dataRegistro.postalCode },
      password: "ontourup",
    });

    //console.log(res_registro);

    if (
      res_registro.data.error ===
      "Error: The email address is already in use by another account."
    ) {
      const res = await api.get(
      
      process.env.REACT_APP_BACK_NEXT +"/v1/account?email=" + correoElectronico
      );
      //console.log(res.data.data._id);
      let id_account = res.data.data._id;

      await this.updateUsuario(
        {
          names: dataRegistro.names,
          surnames: dataRegistro.surnames,
          phone: dataRegistro.phone,
          address: { postalCode: dataRegistro.postalCode },
        },
        id_account
      ).then((res) => {
        //console.log(res)
      });
    }

    await this.comprobarEntradas(correoElectronico, dataEntrada, amount).then(
      (resComprarEntrada) => {
        //console.log(resComprarEntrada)
        if (resComprarEntrada.availability === 0) {
          // console.log("No quedan mas entradas disponibles")
          throw new CustomError("sinentradas", resComprarEntrada.availability);
        }

        if (resComprarEntrada.amountPerPerson <= resComprarEntrada.amount) {
          //console.log("Ya has reservado el maximo de reservas disponibles por persona")
          throw new CustomError("max-entradas", resComprarEntrada.availability);
        }

        if (resComprarEntrada.availability < amount) {
          //console.log("Mas entradas que las disponibles")
          throw new CustomError(
            "max1entradalibre",
            resComprarEntrada.availability
          );
        }

        if (amount > resComprarEntrada.free) {
          // console.log("Ya dispones de una reserva para este evento. Solo puedes reservar una mas")
          throw new CustomError("max1entradamas", {
            amount: resComprarEntrada.amount,
            free: resComprarEntrada.free,
          });
        }
      }
    );

    const res = await this.reservar(dataEntrada);
    //console.log(res.data.data[0]);
    return res.data.data[0];
  }
}

export default new ReservaService();
