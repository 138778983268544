import "../../styles/styles.css";

import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SelectorIdioma from "../../components/SelectorIdioma/SelectorIdioma";
import ciclosService from "../../services/ciclos";

function Home() {

  const [t] = useTranslation("global");
  const [loading, setloading] = useState(true);
  const [ciclos, setCiclos] = useState([]);


  useEffect(() => {
    async function getCiclos() {
      let ciclo = await ciclosService.getCiclos();

      setCiclos(ciclo);
      if (loading) {
        setloading(false);
      }
    }
    getCiclos();
  }, [loading]);
  return (
    <Container fluid className="home">
      {loading === true ? (
        <div></div>
      ) : (
        <div>
          <Row style={{ height: "40px" }}>
            <Col
              style={{
                marginTop: "5px",
                justifyContent: "flex-start",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SelectorIdioma />
            </Col>
          </Row>

          <Row>
            <div
              className="bannerHome"
              alt=""
            ></div>

          </Row>
          <Row
            style={{
              width: "70%",
              marginLeft: "auto",
              marginRight: "auto",
              // marginTop: "-50px",
              justifyContent: "center"
            }}
          >
            <div className="selectorHome">
              {/* <div className="selecciona-el-cicloHome">
                {t("header.title")}
              </div> */}
            </div>
          </Row>
          <Row
            xs={2}
            lg={1}
            style={{
              justifyContent: "center",
              margin: '20px',
              textAlign: "center",
            }}
          >
            <h4
              style={{
                fontSize: "16px",
              }}
            >
              {t("header.Aviso-importante2")}
            </h4>
          </Row>
          <Row
            xs={2}
            lg={1}
            style={{
              justifyContent: "center",
              margin: '20px',
              textAlign: "center",
            }}
          >
            <h4
              style={{
                fontSize: "16px",
              }}
            >
              {t("header.Aviso-importante3")}
            </h4>
          </Row><Row
            xs={2}
            lg={1}
            style={{
              justifyContent: "center",
              marginTop: "20px",
              margin: '20px',
              textAlign: "center",
            }}
          >
            <h4
              style={{
                fontSize: "16px",
              }}
            >
              {t("header.Aviso-importante4")}
            </h4>
          </Row>
          {loading ? (
            <div></div>
          ) : (
            <Row style={{ marginTop: "60px", marginBottom: "100px" }}>
              {ciclos.map((data, key) => {
                return (
                  <Col key={key} style={{ margin: "30px", justifyContent: "center", display: "flex" }} sm={true} md={true}>
                    <Link to={"ciclo/" + data.tags[1]}>
                      <Card
                        style={{
                          borderRadius: "10px",
                          boxShadow: "6px 6px 18px #00000029",
                          minWidth: "300px",
                          maxWidth: "300px",
                        }}
                      >
                        <Card.Img variant="top" src={data.banner} />
                        <Card.Body>
                          <Card.Title>{data.name}</Card.Title>
                        </Card.Body>
                      </Card>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          )}
        </div>
      )}
    </Container>
  );
}

export default Home;