import api from "./index";

class TicketsService {
  async getTickets(idEvento) {
    const res = await api.get(process.env.REACT_APP_BACK_NEXT +"/dashboard/promoter/tickets?_idEvent=" + idEvento);

   
     /* for (const data of res.data.data.results) {   
      await ReservaService.comprobarEntradas(
        "bilbaokultura@ontourup.com",
        {
          ticketsList: [
            {
              email: "bilbaokultura@ontourup.com",
              ticket: data._id,
            },
          ],
        },
        1
      ).then((res) => {
        data.availability = res.availability;
      }); 
    } */

    return res.data.data;
  }
}

export default new TicketsService();
