import axios from 'axios'

export default axios.create({
    baseURL: process.env.REACT_APP_BACK_NEST,
    crossdomain: true,
    headers: {'x-api-key': ''+process.env.REACT_APP_API_KEY}
    
 })


 
 const api_prueba = axios.create ({
    baseURL: 'https://eventos-dev.ontourup.com/api'
 })
 export {api_prueba}

