import * as yup from "yup";

import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useEffect, useState } from "react";

import { CSVLink } from 'react-csv'
import ControlAccesoService from "../../services/controlacceso";
import { Formik } from "formik";
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";

function ControlAcceso({ evento, tickets }) {
  const [t ] = useTranslation("global");

  const schema = yup.object().shape({
    nombre: yup.string().required(t("header.campo-obligatorio")),
    codigoPostal: yup.string().required(t("header.campo-obligatorio")),
    correoElectronico: yup
      .string()
      .email()
      .required(t("header.campo-obligatorio")),
  });
  const filename = ""+ evento.name+".csv"
  const [form, setForm] = useState({ username: "", password: "" });
  const [userState, setUserState] = useState( JSON.parse(localStorage.getItem(""+evento._id)));
  // eslint-disable-next-line no-unused-vars
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setloading] = useState(true);
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const UsuariosRow = (usuario,index) => {

    return(
          <tr key = {index} className='even'>
              <td>{usuario.nombre}</td>
              <td>{usuario.codigoPostal}</td>
              <td>{usuario.correoElectronico}</td>
          </tr>
      )
  }
  
  const tableHeader = <thead className='bgvi'>
  <tr>
      <th>{t("header.nombre")}</th>
      <th>{t("header.codigo-postal")}</th>
      <th>{t("header.email")}</th>

  </tr>
</thead>

const UsuariosTable  = usuarios.map((usuario,index) => UsuariosRow(usuario,index))


  useEffect(() => {
    console.log(loading)
    console.log(usuarios)
  
    localStorage.setItem(""+evento._id, JSON.stringify(usuarios))
    if(loading){
        setloading(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuarios, loading, UsuariosTable])
  const evento2Data = {
    overlapGroup3:
      "https://anima-uploads.s3.amazonaws.com/projects/608138be200c27f99805dc8c/releases/60828d4c841fac091e8381dd/img/rectangle-166-1@1x.png",
  };

  const submit = (values) => {
    usuarios.push(values);
    setloading(true)
    
  }
  return (
    <div className="containerSecundario" style={{ marginTop: "130px" }}>
    
      {userState === null ? (
        <div className="containerPrincipal">
          <div className="form-group">
            <label>{t("header.usuario")} </label>
            <br />
            <input
              type="text"
              className="form-control"
              name="username"
              onChange={handleChange}
            />
            <br />
            <label>{t("header.contrasena")} </label>
            <br />
            <input
              type="password"
              className="form-control"
              name="password"
              onChange={handleChange}
            />
            <br />
            <button
              className="btn btn-primary"
              onClick={() =>
                ControlAccesoService.iniciarSesion(
                  form.username,
                  form.password
                ).then((res) => {
                  setUserState(res);
                })
              }
            >
              {t("header.iniciar-sesion")}
            </button>
          </div>
        </div>
      ) : (
        <Container>
          <Formik
            validationSchema={schema}
            onSubmit={ (values) => {
              submit(values)
            }}
            initialValues={{
              nombre: "",
              codigoPostal: "",
              correoElectronico: "",
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="datos-persona">
                  <Form.Group as={Col} controlId="formNombre">
                    <div className="nombre-1 montserrat-normal-thunder-15px">
                      {t("header.nombre")}
                    </div>
                    <Form.Control
                      type="text"
                      name="nombre"
                      value={values.nombre}
                      onChange={handleChange}
                      isInvalid={errors.nombre}
                      placeholder={t("header.nombre-tooltip")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.nombre}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formCodigoPostal">
                    <div className="nombre-1 montserrat-normal-thunder-15px">
                      {t("header.codigo-postal")}
                    </div>
                    <Form.Control
                      type="text"
                      name="codigoPostal"
                      value={values.codigoPostal}
                      onChange={handleChange}
                      isInvalid={errors.codigoPostal}
                      placeholder={t("header.codigo-postal-tooltip")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.codigoPostal}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" controlId="formCorreo">
                    <div className="correo-electronico montserrat-normal-thunder-15px">
                      {t("header.email")}
                    </div>
                    <Form.Control
                      type="email"
                      name="correoElectronico"
                      value={values.correoElectronico}
                      onChange={handleChange}
                      isInvalid={errors.correoElectronico}
                      placeholder={t("header.email-tooltip")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.correoElectronico}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row style={{display: "flex", justifyContent:"center", marginBottom: "100px"}}>
                  <Button
                    className="buttonnormalRes"
                    variant="flat"
                    type="submit"
                    onClick={handleSubmit}
                    style={{
                      backgroundImage: `url(${evento2Data.overlapGroup3})`,
                      color: "white", marginTop:"0", marginBottom:"50px"
                    }}
                  >
                    <div className="overlap-group3Res" style={{justifyContent:"center" }}>
                      <div className="siguiente montserrat-normal-white-18px">
                        {t("header.anadir")}
                      </div>
                    </div>
                  </Button>
                </Row>
              </Form>
            )}
          </Formik>
          {loading ? <Spinner animation= "border"/> : <Table striped bordered hover>
            {tableHeader}
            <tbody>
                {UsuariosTable}
            </tbody>
        </Table>}
        <Row>
        <Button variant="warning">
            <CSVLink  data={usuarios} filename={filename}>{t("header.exportar")}</CSVLink>
        </Button>
        </Row>
        </Container>
      )}
    </div>
  );
}



export default ControlAcceso;
