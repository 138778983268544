/* eslint-disable no-unused-vars */

import "../../styles/styles.css";

import { Col, Row, Spinner, Tab } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { getDiaEvento, getHoraFmt } from "./../../utils/fechas";

import CartaEvento from "./../CartaEvento/CartaEvento";
import { useTranslation } from "react-i18next";

function Calendario({ eventos_ciclo, ciclo }) {
  //console.log(ciclo.tags[1]);


  const [t, i18n] = useTranslation("global");
  const [loading, setLoading] = useState(true);

  
  if (ciclo.description !== "") {
    var arrayDeCadenas = ciclo.description.split("#");
    var parrafoEspañol = arrayDeCadenas[1].split(".-");
    var parrafoEuskera = arrayDeCadenas[0].split(".-");
  } else {
     parrafoEspañol = []
     parrafoEuskera = []
  }

  useEffect(() => {



    eventos_ciclo.forEach((element) => {

      element.tickets.forEach((element) => {
        element.hora_fmt = getHoraFmt(element.startDate);
      });
      element.dia_fmt = getDiaEvento(element.startDate);
      element.dia_fmt = getDiaEvento(element.startDate);
      let minutes;
      let hora = new Date(element.startDate).getHours();
      if (new Date(element.startDate).getMinutes() < 10) {
        minutes = "0" + new Date(element.startDate).getMinutes();
      } else {
        minutes = new Date(element.startDate).getMinutes();
      }
      element.hora_fmt = hora + ":" + minutes;
    });

    //console.log(eventos_ciclo)


    if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <Tab.Container >
          <div>
            {i18n.language === "es" ? (
              <div>
                <Row
                  style={{
                    marginTop: "50px",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                >
                  {parrafoEspañol.map((element, key) => {
                    return (
                      <ul key={key} className="parrafoIndicacion2">
                        {"-" + element}
                      </ul>
                    );
                  })}
                </Row>
              </div>
            ) : (
              <Row
                style={{
                  marginTop: "50px",
                  marginLeft: "20px",
                  marginRight: "20px",
                }}
              >
                {parrafoEuskera.map((element, key) => {
                  return (
                    <ul key={key} className="parrafoIndicacion2">
                      {"-" + element}
                    </ul>
                  );
                })}
              </Row>
            )}


          </div>

          {/* <Row xs={2} lg={1} style={{ justifyContent: "center" }}>
            <h2
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop:"50px",
                textAlign: "center",
                fontSize: "22px",
              }}
            >
              {t("header.recomendacion-1")}
            </h2>
          </Row>

          <Row xs={2} lg={1} style={{ justifyContent: "center" }}>
            <h2
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop:"20px",
                textAlign: "center",
                fontSize: "22px",
              }}
            >
              - {t("header.recomendacion-2")}
            </h2>
          </Row>

          <Row xs={2} lg={1} style={{ justifyContent: "center" }}>
            <h2
              style={{
                justifyContent: "center",
                display: "flex",
                textAlign: "center",
                fontSize: "22px",
              }}
            >
              - {t("header.recomendacion-3")}
            </h2>
          </Row>

          <Row xs={2} lg={1} style={{ justifyContent: "center" }}>
            <h2
              style={{
                justifyContent: "center",
                display: "flex",
                textAlign: "center",
                fontSize: "22px",
              }}
            >
              - {t("header.recomendacion-4")}
            </h2>
          </Row>

          <Row xs={2} lg={1} style={{ justifyContent: "center" }}>
            <h2
              style={{
                justifyContent: "center",
                display: "flex",
                textAlign: "center",
                fontSize: "22px",
              }}
            >
              - {t("header.recomendacion-5")}
            </h2>
          </Row>

          <Row xs={2} lg={1} style={{ justifyContent: "center" }}>
            <h2
              style={{
                justifyContent: "center",
                display: "flex",
                textAlign: "center",
                fontSize: "22px",
              }}
            >
              - {t("header.recomendacion-6")}
            </h2>
          </Row>

          <Row xs={2} lg={1} style={{ justifyContent: "center" }}>
            <h2
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: "30px",
                textAlign: "center",
                fontSize: "22px",
              }}
            >
              {t("header.recomendacion-7")}
            </h2>
          </Row> */}

          <div
            className="row row-cols-lg-10 row-cols-1"
            style={{ marginLeft: "0", marginBottom: "150px" }}
          >
            {eventos_ciclo.length !== 0 ? <Tab.Content>
              <Row style={{ justifyContent: "center" }}>
                <Row
                  style={{
                    justifyContent: "center",
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                >
                  {eventos_ciclo.map((data, i) => {
                    return <CartaEvento key={i} evento={data} />;
                  })}
                </Row>
              </Row>
            </Tab.Content> : <div></div>}

          </div>
        </Tab.Container>
      )}
    </div>
  );
}

export default Calendario;
