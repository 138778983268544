import "./App.css";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import CanjeBonos from "./components/Bonos/canjeBonos";
import { Container } from "react-bootstrap";
import Evento from "./pages/event/event";
import Home from "./pages/home/Home";
import ListaEventos from "./pages/listaEventos/listaEventos";

//import Footer from './components/Footer/footer';

function App() {
  return (
    <Container fluid style={{ paddingLeft: "0", paddingRight: "0" }}>
      <Router>
        <Switch>
          <Route exact={true} path="/" component={Home} />
          <Route
            path="/ciclo/:ciclo"
            render={({ match }) => {
              return <ListaEventos ciclo_key={match.params.ciclo} />;
            }}
          />
          <Route
            path="/evento/:eventoId"
            render={({ match }) => <Evento eventoID={match.params.eventoId} />}
          />
          {/* <Route
            path="/canjeBonos"
            component={CanjeBonos} 
          /> */}
        </Switch>
      </Router>
    </Container>
  );
}

export default App;
