import 'moment/locale/es';

import moment from 'moment';

moment.locale('es');

export function getDiaEvento(startDate){
    let fecha = startDate.split("T")
    let substrings =fecha[0].split("-")
    return substrings[2]
}

export function getMesEvento(startDate){
    let fecha = startDate.split("T")
    let substrings =fecha[0].split("-")
    return substrings[1]
}

export function getHoraFmt(startDate, idioma){
/*     let fecha = startDate.split("T")
    let fechas = fecha[1].split(":")
    let hora_event = fechas[0]
    let minuto_event = fechas[1] */
    const hora_event = moment(startDate).format('HH');
    const minuto_event = moment(startDate).format('mm');
    
    return hora_event+":"+minuto_event
}

export function getFechaFmt(startDate, idioma){
    let fecha = startDate.split("T")
    let fechas = fecha[0].split("-")
    let dia_event = getDiaEvento(startDate)
    let mes_event = fechas[1]
    let ano_event = fechas[0]
    switch (idioma) {
        case "01":
            
            break;
    
        default:
            break;
    }

    if(idioma === "es"){
        return dia_event+"/"+mes_event+"/"+ano_event
    }

    if(idioma === "eus"){
        return ano_event+"/"+mes_event+"/"+dia_event
    }
    
}