import "./infoEvento.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Col, Row } from "react-bootstrap";

import { Carousel } from "react-responsive-carousel";
import GoogleMaps from "simple-react-google-maps";
import React from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";

function InfoEvento({ evento }) {
  //console.log(evento);
  var longitud = parseFloat(evento.ubication.gps.longitude);
  var latitud = parseFloat(evento.ubication.gps.latitude);
  const [t, i18n] = useTranslation("global");

  var arrayDeCadenas = evento.description.split("#");
  var descripcionEus = arrayDeCadenas[0].split("\n\n");
  var descripcionEs = arrayDeCadenas[1].split("\n\n");

  //console.log(descripcionEs);
  return (
    <div>
      <Row>
        <Col style={{ marginBottom: "50px" }}>
          <div className="descripcin montserrat-medium-thunder-25px">
            {t("header.descripcion")}
          </div>
          <div className="la-cantante-y-compos">
            {i18n.language === "es" ? (
              <div style={{ justifyContent: "center" }}>
                {descripcionEs.map((data, key) => {
                  return (
                    <div key={key}>
                      <Row>{data}</Row>
                      <br></br>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                {descripcionEus.map((data, key) => {
                  return (
                    <div key={key}>
                      <Row>{data}</Row>
                      <br></br>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {evento.galery.length > 0 ? (
            <div style={{ marginTop: "50px" }}>
              <Carousel showArrows={true}>
                {evento.galery.map((data, key) => {
                  return (
                    <div key={key}>
                      <img src={data} alt="" />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            <>
              {evento.url.includes("youtube") ||
              evento.url.includes("vimeo") ? (
                <div>
                  <div className="descripcin" style={{ marginTop: "50px" }}>
                    {" "}
                    {t("header.video")} {t("header.video-1")}
                  </div>

                  <ReactPlayer
                    style={{ marginTop: "50px" }}
                    url={evento.url}
                    className="react-player"
                    playing={false}
                    height="360px"
                    width="auto"
                    controls
                  />
                </div>
              ) : (
                <div></div>
              )}
            </>
          )}
        </Col>
        <Col style={{ marginBottom: "50px" }}>
          <div className="overlap-group1">
            <Row>
              <Col style={{ marginLeft: "20px" }}>
                <div className="ciclo-1 montserrat-medium-thunder-25px">
                  {t("header.ciclo")}
                </div>
                {evento.tags.map((data, key) => {
                  return (
                    <Row key={key}>
                      {evento.name === "𝗣𝗥𝗘𝗠𝗜𝗢𝗦 𝗞𝗢𝗦𝗞𝗔 𝗦𝗔𝗥𝗜𝗔𝗞 𝟮𝟬𝟮𝟭" ? (
                        <>
                          <h1 className="title">{t("header.premioKoska")}</h1>
                        </>
                      ) : (
                        <>
                          <h1 className="title">{data}</h1>
                        </>
                      )}
                    </Row>
                  );
                })}
              </Col>

              <Col>
                <div style={{left:"45px"}} className="ciclo-1 montserrat-medium-thunder-25px">
                  {t("header.idioma")}
                </div>
                {evento.name === "𝗣𝗥𝗘𝗠𝗜𝗢𝗦 𝗞𝗢𝗦𝗞𝗔 𝗦𝗔𝗥𝗜𝗔𝗞 𝟮𝟬𝟮𝟭" ? (
                  <>
                    <h1 className="title" style={{left:"0"}}>{t("header.idiomaPremio")}</h1>{" "}
                  </>
                ) : (
                  <>
                    {evento.language_event === "Castellano"
                      ? t("header.castellano")
                      : ""}
                    {evento.language_event === "Euskera"
                      ? t("header.euskera")
                      : ""}
                    {evento.language_event === "Otros" ? t("header.otros") : ""}
                    {evento.language_event === "Bilingue"
                      ? t("header.bilingue")
                      : ""}
                    {evento.language_event === "Frances"
                      ? t("header.frances")
                      : ""}
                    {evento.language_event === "Ingles"
                      ? t("header.ingles")
                      : ""}
                    {evento.language_event === "Obra Muda"
                      ? t("header.obra-muda")
                      : ""}
                  </>
                )}
              </Col>
            </Row>
          </div>

          <div className="ubicacionEven">
            <div style={{ width: "100%" }}>
              <div className="ubicacinEven montserrat-medium-thunder-25px">
                {t("header.ubicacion")}

                {/* <GoogleMapReact
                  mapContainerStyle={containerStyle}
                  center={{
                    lat: evento.ubication.gps.latitude,
                    lng: evento.ubication.gps.latitude,
                  }}
                  zoom={10}
                  onLoad={onLoad}
                ></GoogleMapReact> */}
              </div>
              <div>
                <GoogleMaps
                  apiKey={"AIzaSyCEpRrgB2-1d7Ph48KbvZmK6o7nImqSm4Q"}
                  // apiKey={"AIzaSyCy7NFqmPnE7N8WG2xerQKcJ2ArSO9Gl5A"}
                  style={{
                    height: "300px",
                    width: "auto",
                    top: "20px",
                  }}
                  zoom={15}
                  center={{
                    lat: latitud,
                    lng: longitud,
                  }}
                  markers={[{ lat: latitud, lng: longitud }]}
                />
              </div>

              <h6 className="text-3Even">{evento.ubication.location}</h6>
              <p className="text-DirEven">
                {evento.ubication.address}, {evento.ubication.postalCode}.{" "}
              </p>
              {/* <p className="text-DirEven">
                {evento.ubication.city}
                {", "}
                {evento.ubication.province}
              </p> */}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default InfoEvento;
