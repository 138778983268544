import "../../styles/styles.css";

import { Col, Container, Row, Spinner, Tab, Nav } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { getDiaEvento, getHoraFmt } from "../../utils/fechas";

import { useLocation } from "react-router-dom";
import SelectorIdioma from "../SelectorIdioma/SelectorIdioma";
import { useTranslation } from "react-i18next";
import CartaEvento from "../CartaEvento/CartaEvento";

function CanjeBonos({}) {
  /* console.log(this.location.state.eventos_ciclo); */
  let location = useLocation();
  // console.log(location.state.eventos_ciclo);
  var eventos_ciclo = location.state.eventos_ciclo;
  const [mes, setMes] = useState("");
  const [nav, setNav] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [eventos_julio, setEventosJulio] = useState([]);
  const [eventos_agosto, setEventosAgosto] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    eventos_julio.length = 0;
    eventos_agosto.length = 0;

    eventos_ciclo.forEach((element) => {
      if (element.startDate.includes("2021-09")) {
        element.tickets.forEach((element) => {
          element.hora_fmt = getHoraFmt(element.startDate);
        });
        element.dia_fmt = getDiaEvento(element.startDate);
        element.dia_fmt = getDiaEvento(element.startDate);
        let minutes;
        let hora = new Date(element.startDate).getHours();
        if (new Date(element.startDate).getMinutes() < 10) {
          minutes = "0" + new Date(element.startDate).getMinutes();
        } else {
          minutes = new Date(element.startDate).getMinutes();
        }
        element.hora_fmt = hora + ":" + minutes;
        eventos_julio.push(element);
      }
      //console.log(eventos_julio);
      if (element.startDate.includes("2021-10")) {
        element.tickets.forEach((element) => {
          element.hora_fmt = getHoraFmt(element.startDate);
        });

        element.dia_fmt = getDiaEvento(element.startDate);
        element.dia_fmt = getDiaEvento(element.startDate);
        let minutes;
        let hora = new Date(element.startDate).getHours();
        if (new Date(element.startDate).getMinutes() < 10) {
          minutes = "0" + new Date(element.startDate).getMinutes();
        } else {
          minutes = new Date(element.startDate).getMinutes();
        }
        element.hora_fmt = hora + ":" + minutes;
        eventos_agosto.push(element);
      }
    });

    if (!nav) {
      if (eventos_julio.length > 0) {
        setMes("septiembre");
      } else if (eventos_agosto.length > 0) {
        setMes("octubre");
      }
    }

    if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <Container fluid className="home">
      {loading === true ? (
        <div></div>
      ) : (
        <Tab.Container defaultActiveKey={mes}>
          <div>
            <Row style={{ height: "40px" }}>
              <Col
                style={{
                  position: "absolute",
                  right: "0%",
                  justifyContent: "flex-start",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SelectorIdioma />
              </Col>
            </Row>

            <Row>
              <div className="bannerHome" alt=""></div>
            </Row>
            <Row
              style={{
                width: "70%",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "-50px",
                justifyContent: "center",
              }}
            >
              <div className="selectorHome">
                <div className="selecciona-el-cicloHome">Canje de Bonos</div>
              </div>
            </Row>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <Container style={{ marginTop: "60px" }}>
                <Row style={{ justifyContent: "center", display: "flex" }}>
                  <Col
                    sm={6}
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <Col
                      sm={8}
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      Usuario: prueba@gmail.com
                    </Col>
                  </Col>
                  <Col
                    sm={6}
                    style={{ justifyContent: "center", display: "flex" }}
                  >
                    <Col
                      sm={8}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginLeft: "20px",
                      }}
                    >
                      {" "}
                      informacion de bono: le quedan 25 bonos
                    </Col>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginLeft: "0",
                    marginRight: "0",
                    marginTop: "60px",
                  }}
                  xs={1}
                  lg={2}
                >
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      height: "60px",
                    }}
                  >
                    <div className="selecion-mesCal">
                      <h4 className="calendario-eventos text-wrap">
                        eventos Disponibles:
                      </h4>
                    </div>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                    }}
                  >
                    <div className="mesesCal">
                      <Nav
                        variant="tabs"
                        defaultActiveKey={mes}
                        onSelect={(selectedKey) => {
                          switch (selectedKey) {
                            case "septiembre":
                              setLoading(true);
                              setNav(true);
                              setMes("septiembre");
                              break;
                            case "octubre":
                              setLoading(true);
                              setNav(true);
                              setMes("octubre");
                              break;
                            default:
                              break;
                          }
                        }}
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="septiembre">
                            {t("header.septiembre")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="octubre">octubre</Nav.Link>
                        </Nav.Item>
                        <Nav.Item></Nav.Item>
                      </Nav>
                    </div>
                  </Col>
                </Row>

                <div
                  className="row row-cols-lg-10 row-cols-1"
                  style={{ marginLeft: "0", marginBottom: "150px" }}
                >
                  <Tab.Content>
                    <Tab.Pane eventKey="septiembre">
                      <Row
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        <Row
                          style={{
                            justifyContent: "center",
                            marginLeft: "0px",
                            marginRight: "0px",
                          }}
                        >
                          {eventos_julio.map((data, i) => {
                            //console.log(data);
                            return <CartaEvento key={i} evento={data} />;
                          })}
                        </Row>
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="octubre">
                      <Row style={{ justifyContent: "center" }}>
                        <Row
                          style={{
                            justifyContent: "center",
                            marginLeft: "0px",
                            marginRight: "0px",
                          }}
                        >
                          {eventos_agosto.map((data, i) => {
                            console.log(data);
                            return <CartaEvento key={i} evento={data} />;
                          })}
                        </Row>
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Container>
            )}
          </div>
        </Tab.Container>
      )}
    </Container>
  );
}

export default CanjeBonos;
